define("discourse/plugins/discourse-canned-replies/controllers/new-reply", ["exports", "@ember/controller", "discourse/mixins/modal-functionality", "discourse/lib/show-modal", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse/lib/ajax-error"], function (_exports, _controller, _modalFunctionality, _showModal, _ajax, _decorators, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, (_dec = (0, _decorators.default)("newTitle", "newContent"), (_obj = {
    newTitle: "",
    newContent: "",
    onShow() {
      this.setProperties({
        newTitle: "",
        newContent: ""
      });
    },
    disableSaveButton(newTitle, newContent) {
      return newTitle === "" || newContent === "";
    },
    actions: {
      save() {
        (0, _ajax.ajax)("/canned_replies", {
          type: "POST",
          data: {
            title: this.newTitle,
            content: this.newContent
          }
        }).then(() => {
          this.send("closeModal");
          if (this.site.mobileView) {
            (0, _showModal.default)("canned-replies");
          } else {
            this.appEvents.trigger("canned-replies:show");
          }
        }).catch(_ajaxError.popupAjaxError);
      },
      cancel() {
        this.send("closeModal");
        if (this.site.mobileView) {
          (0, _showModal.default)("canned-replies");
        } else {
          this.appEvents.trigger("canned-replies:show");
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "disableSaveButton", [_dec], Object.getOwnPropertyDescriptor(_obj, "disableSaveButton"), _obj)), _obj)));
});