define("discourse/plugins/discourse-canned-replies/controllers/edit-reply", ["exports", "@ember/controller", "discourse/mixins/modal-functionality", "discourse/lib/show-modal", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse/lib/ajax-error", "I18n", "@ember/service"], function (_exports, _controller, _modalFunctionality, _showModal, _ajax, _decorators, _ajaxError, _I18n, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, (_dec = (0, _decorators.default)("saving"), _dec2 = (0, _decorators.default)("replyTitle", "replyContent", "saving"), (_obj = {
    dialog: (0, _service.inject)(),
    replyTitle: "",
    replyContent: "",
    replyId: "",
    saving: null,
    onShow() {
      this.set("saving", null);
    },
    savingLabel(saving) {
      return saving === null ? "save" : saving ? "saving" : "saved";
    },
    disableSaveButton(replyTitle, replyContent, saving) {
      return saving || replyTitle === "" || replyContent === "";
    },
    actions: {
      save() {
        this.set("saving", true);
        (0, _ajax.ajax)(`/canned_replies/${this.replyId}`, {
          type: "PATCH",
          data: {
            title: this.replyTitle,
            content: this.replyContent
          }
        }).catch(_ajaxError.popupAjaxError).finally(() => {
          this.set("saving", false);
          this.appEvents.trigger("canned-replies:show");
        });
      },
      remove() {
        this.dialog.deleteConfirm({
          message: _I18n.default.t("canned_replies.edit.remove_confirm"),
          didConfirm: () => {
            return (0, _ajax.ajax)(`/canned_replies/${this.replyId}`, {
              type: "DELETE"
            }).then(() => {
              this.send("closeModal");
              if (this.site.mobileView) {
                (0, _showModal.default)("canned-replies");
              } else {
                this.appEvents.trigger("canned-replies:show");
              }
            }).catch(_ajaxError.popupAjaxError);
          }
        });
      },
      cancel() {
        this.send("closeModal");
        if (this.site.mobileView) {
          (0, _showModal.default)("canned-replies");
        } else {
          this.appEvents.trigger("canned-replies:show");
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "savingLabel", [_dec], Object.getOwnPropertyDescriptor(_obj, "savingLabel"), _obj), _applyDecoratedDescriptor(_obj, "disableSaveButton", [_dec2], Object.getOwnPropertyDescriptor(_obj, "disableSaveButton"), _obj)), _obj)));
});