define("discourse/plugins/discourse-canned-replies/controllers/canned-replies", ["exports", "@ember/controller", "discourse/mixins/modal-functionality", "discourse/lib/show-modal", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse/lib/ajax-error", "discourse/plugins/discourse-canned-replies/lib/apply-reply"], function (_exports, _controller, _modalFunctionality, _showModal, _ajax, _decorators, _ajaxError, _applyReply) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, (_dec = (0, _decorators.observes)("selectedReplyId"), (_obj = {
    selectedReply: null,
    selectedReplyId: "",
    loadingReplies: true,
    canEdit: false,
    init() {
      this._super(...arguments);
      const currentUser = this.get("currentUser");
      const everyoneCanEdit = this.siteSettings.canned_replies_everyone_enabled && this.siteSettings.canned_replies_everyone_can_edit;
      const currentUserCanEdit = this.siteSettings.canned_replies_enabled && currentUser && currentUser.can_edit_canned_replies;
      const canEdit = currentUserCanEdit ? currentUserCanEdit : everyoneCanEdit;
      this.set("canEdit", canEdit);
      this.replies = [];
    },
    _updateSelection() {
      this.selectionChange();
    },
    onShow() {
      (0, _ajax.ajax)("/canned_replies").then(results => {
        this.set("replies", results.replies);
        // trigger update of the selected reply
        this.selectionChange();
      }).catch(_ajaxError.popupAjaxError).finally(() => this.set("loadingReplies", false));
    },
    selectionChange() {
      const localSelectedReplyId = this.get("selectedReplyId");
      let localSelectedReply = "";
      this.get("replies").forEach(entry => {
        if (entry.id === localSelectedReplyId) {
          localSelectedReply = entry;
          return;
        }
      });
      this.set("selectedReply", localSelectedReply);
    },
    actions: {
      apply() {
        (0, _applyReply.default)(this.get("selectedReplyId"), this.selectedReply.title, this.selectedReply.content, this.composerModel);
        this.send("closeModal");
      },
      newReply() {
        this.send("closeModal");
        (0, _showModal.default)("new-reply").set("newContent", this.composerModel.reply);
      },
      editReply() {
        this.send("closeModal");
        (0, _showModal.default)("edit-reply").setProperties({
          replyId: this.selectedReplyId,
          replyTitle: this.get("selectedReply.title"),
          replyContent: this.get("selectedReply.content")
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "_updateSelection", [_dec], Object.getOwnPropertyDescriptor(_obj, "_updateSelection"), _obj)), _obj)));
});